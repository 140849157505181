<template>
    <v-container
        tag="section"
    >
        <v-row
            justify="center"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                          Рекомендации по заболеваниям
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Рекомендации по заболеваниям - инструмент для подбора бадов в зависимости от вопросов по здоровью.
                      </p>
                      <p class="mb-10">
                        Цель - Помощь в подборе комбинаций и дозировок бадов в зависимости от запросов клиента. Увеличение продаж продукта за счет расширения потребительской корзины.
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="red">mdi-numeric-1-box-multiple</v-icon>
                                  Инструкция по наборам БАДов
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <a href="https://teletype.in/@newlvl21/CPn31X_bjrs" target="_blank">Инструкция по наборам БАДов</a>
                              </p>                   
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="blue">mdi-numeric-2-box-multiple</v-icon>
                                  Дозировки и рекомендации по БАДам
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <a href="/files/learn/dozirovki.pdf" target="_blank">Дозировки и рекомендации по БАДам</a>
                              </p>                   
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Аллергия
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/allergiya.jpeg" />
                              </p>   
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Алоэ Вера гель Персик: </strong>
                              </p>
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p>
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл.  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл <br />
                              </p>
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Минералы Пробаланс: </strong>
                              </p>
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма <br />
                              </p>
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки <br />
                              </p>
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Рекомендации доктора Жана- Бернара Дельбе: </strong>
                              </p>
                              <p class="mt-5">
                                <strong><span style="font-style: italic;">Гель алоэ Вера персик/гель алоэ Вера мёд: </span></strong><br />
                                От 30 мл -3 раза в день до 100 мл - 3 раза в день. 
                              </p>
                              <p class="mt-7">
                                <strong><span style="font-style: italic;">Минералы пробаланс: </span></strong><br />
                                От 2-х таблеток - 3 раза в день до 2-х таблеток - 8 раз в день. 
                              </p>
                              <p class="mt-7">
                                <strong><span style="font-style: italic;">Пробиотик про12: </span></strong><br />
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день. 
                              </p>
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Скорая помощь: </strong>
                              </p>
                              <p>
                                Если аллергия проявляется высыпаниями, то наносим на кожу сначала спрей Скорая помощь, через минуту (после того как спрей подсохнет), наносим гель концентрат. <br />
                                Кратность: минимум 2-3 раза 
                              </p>
                              <p>
                                Если аллергия проявляется насморком, то спрей Скорая помощь брызгаем в нос. <br />
                                Минимум 3 раза в день.
                              </p>                
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Бронхит
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/bronhit.jpeg" />
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Алоэ Вера гель Мёд:</strong>
                              </p>          
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p>          
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл <br />
                              </p>          
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Минералы Пробаланс: </strong>
                              </p>          
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма <br />
                              </p>          
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки <br />
                              </p>          
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Супер омега 3:</strong>
                              </p>          
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.<br />
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Цистус инканус капсулы:</strong>
                              </p>          
                              <p>
                                1 капсула -2 раза в день 
                              </p>          
                              <p>
                                <strong>Дети с 3 лет:  </strong><br />
                                профилактика - 1 капсула за 30 кг веса в сутки.          
                              </p>          
                              <p>
                                <strong>С лечебной целью по схеме: </strong><br />
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                              </p>          
                              <p>
                                Принимать во время еды. 
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Цистус инканус спрей:</strong>
                              </p>          
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.<br />
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Гриб рейши: </strong>
                              </p>          
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>          
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.<br />
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                               <strong> Селл Эссенс Фуд: </strong>
                              </p>          
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после. <br />
                              </p>          
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Термолосьон: </strong>
                              </p>          
                              <p>
                                наносить, растирая, на грудную клетку и спину (проекция грудной клетки).
                              </p>         
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Варикоз
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/varikoz.jpeg" />
                              </p>   
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Алоэ Вера гель крапива: </strong>
                              </p>   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p>   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл <br />
                              </p>   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>   
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Минералы Пробаланс: </strong>
                              </p>   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма <br />
                              </p>   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки <br />
                              </p>   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>   
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Супер омега-3: </strong>
                              </p>   
                              <p>
                                С 3 до 15 лет - 1 раз в день 1 капсула. <br />
                                До 20 лет - 1 раз в день 2 капсулы. <br />
                                От 20 лет - 1 раз в день 3 капсулы. <br />
                              </p>   
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Рекомендации доктора Жана-Бернара Дельбе: </strong>
                              </p>   
                              <p class="mt-5" style="font-style: italic;">
                                <strong>Гель алоэ Вера с экстрактом крапивы:</strong><br />
                                от 3 раз в день х 50 мл <br />
                                до 3 раз в день х 100 мл <br />
                              </p>     
                              <p class="mt-10" style="font-style: italic;">
                               <strong> Минералы Пробаланс: </strong><br />
                               от 3 раз в день х 2 таблетки <br />
                               до 8 раз в день х 2 таблетки
                              </p>   
                              <p class="mt-10" style="font-style: italic;">
                                <strong>Супер омега 3:</strong><br />
                                от 2 раз в день х 1 капсула <br />
                                до 3 раз в день x 2 капсулы. <br />
                              </p>     
                              <p class="mt-10" style="font-style: italic;">
                                <strong>Гриб Рейши: </strong><br />
                                2 раза в день по 1 капсуле. <br />
                                (Подходит для любой возрастной группы.) <br />
                              </p>    
                              <p>
                                Наружно: 2-3 раза в день (минимум) наносим гель концентрат на варикозно расширенные вены. Поверх концентрата наносим мсм гель.
                              </p>                
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Гастрит
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/gastrit.jpeg" />
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Алоэ Вера гель Персик/Мёд:</strong>
                              </p>      
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p>      
                              <p>
                                <strong>Возрастная норма:</strong> <br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл <br />
                              </p>      
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Минералы Пробаланс: </strong>
                              </p>      
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма <br />
                              </p>      
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки <br />
                              </p>      
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Пробиотик про12: </strong>
                              </p>      
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Селл Эссенс Фуд: </strong>
                              </p>      
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Майнд мастер: </strong>
                              </p>      
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. <br />
                              </p>      
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. <br />
                              </p>      
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>      
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Гриб рейши: </strong>
                              </p>      
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>      
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>             
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Дыхательная система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/breath-sys.jpeg" />
                              </p> 
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Мёд:
                              </p> 
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p> 
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл <br />
                              </p> 
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p> 
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Минералы Пробаланс: </strong>
                              </p> 
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма <br />
                              </p> 
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки <br />
                              </p> 
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p> 
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Майнд мастер: </strong>
                              </p> 
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p> 
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. <br />
                              </p> 
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p> 
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Супер омега 3:</strong>
                              </p> 
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                              </p> 
                              <p class="mt-10" style="text-transform: uppercase;">
                                <strong>Цистус инканус капсулы:</strong>
                              </p> 
                              <p>
                                1 капсула -2 раза в день 
                              </p> 
                              <p>
                                <strong>Дети с 3 лет: </strong>
                                профилактика - 1 капсула за 30 кг веса в сутки. <br />
                              </p> 
                              <p>
                                <strong>С лечебной целью по схеме: </strong>
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. 
                              </p> 
                              <p>
                                Принимать во время еды. 
                              </p> 
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p> 
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать.<br /> 
                              </p> 
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p> 
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p> 
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p> 
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p> 
                              <p>
                                Принимать утром. <br />
                                Растворить 2 мерные ложки порошка (6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы. <br />
                                 Можно за пол часа до каши или через час после. 
                              </p> 
                              <p class="mt-10 p-title">
                                Термолосьон: 
                              </p> 
                              <p>
                                наносить, растирая на грудную клетку и спину (проекция грудной клетки).
                              </p>                  
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Железодефицитная анемия
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/ferum-anemiya.jpeg" />
                              </p>         
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик: 
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. <br />
                              </p>         
                              <p>
                                <strong>Возрастная норма:</strong> <br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                              </p>         
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>         
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>         
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>         
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>         
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>         
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>         
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>         
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>         
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>         
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>         
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>         
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>         
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой.
                              </p>         
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>         
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>         
                              <p class="mt-10 p-title">
                                Селл Эссенс Энерджи: 
                              </p>         
                              <p>
                                Принимать днём. Растворить 1 мерную ложку порошка (3,4 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте.
                              </p>         
                              <p class="mt-10 p-title">
                                Селл Эссенс Регенерэйшн: 
                              </p>         
                              <p>
                                Принимать вечером. Растворить 1,5 мерные ложки (4,7 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте.
                              </p>         
                              <p class="mt-10 p-title">
                                Протеин Пауэр: 
                              </p>         
                              <p>
                                С 3 лет принимать по 1 порции в день, вечером, во время еды. <br />
                                Растворить 1 мерную ложку порошка в 125 мл обезжиренного молока или в 100 мл воды. <br />
                                Для приготовления рекомендуется использовать шейкер. <br />
                                Перед приготовлением встряхнуть. <br />
                                Хранить в сухом прохладном месте. <br />
                                После вскрытия хранить не более 1 месяца.
                              </p>          
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Желудочно-кишечный тракт
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/gkt.jpeg" />
                              </p>       
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик:
                              </p>       
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>       
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>       
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>       
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>       
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>       
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>       
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>       
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>       
                              <p>
                                Принимать утром.<br />
                                Растворить 2 мерные ложки порошка (6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы.<br />
                                Можно за пол часа до каши или через час после. 
                              </p>       
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>       
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>       
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>       
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном.
                              </p>       
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>       
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>       
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>       
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>       
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>       
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>       
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>            
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Иммунная система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/immun-sys.jpeg" />
                              </p>  
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Иммун плюс: 
                              </p>  
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>  
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>  
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>  
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>  
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>  
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>  
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>  
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>  
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>  
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>  
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>  
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>  
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>  
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>  
                              <p>
                                1 капсула -2 раза в день 
                              </p>  
                              <p>
                                <strong>Дети с 3 лет: </strong>
                                профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>  
                              <p>
                                <strong>С лечебной целью по схеме: </strong>
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>  
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>  
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>  
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>  
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>  
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>  
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>  
                              <p>
                                Принимать утро. <br />
                                Растворить 2 мерные ложки порошка (6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы. <br />
                                Можно за пол часа до каши или через час после.
                              </p>  
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>  
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>  
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой.
                              </p>                 
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Ишемия мозга, задержка развития
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/ishemiya.jpeg" />
                              </p>     
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик/Сивера: 
                              </p>     
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>     
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>     
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>     
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>     
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>     
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>     
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>     
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>     
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>     
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>     
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>     
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>     
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>     
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>     
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>     
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>     
                              <p class="mt-10 p-title">
                                Селл Эссенс Энерджи: 
                              </p>     
                              <p>
                                Принимать днём. <br />
                                Растворить 1 мерную ложку порошка (3,4 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте.
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Кожа
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/skin.jpeg" />
                              </p>                   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик:
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>                   
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>                   
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>                   
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном.
                              </p>                   
                              <p class="mt-10 p-title">
                                Бьюти элексир: 
                              </p>                   
                              <p>
                                Принимать ежедневно по одному флакону. <br />
                                Идеально подходит для женщин в возрасте 25+ , которые хотят продлить или вернуть молодость. <br />
                                Также подходит для мужчин. <br />
                                Перед использованием встряхнуть. <br />
                                Хранить в сухом месте, при комнатной температуре.
                              </p>                   
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>                   
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>                   
                              <p class="mt-10 p-title">
                                Наружно аптечка первая помощь: 
                              </p>                   
                              <p>
                                на поражённые участки кожи наносим сначала спрей Скорая помощь, через минуту концентрат. После того как концентрат подсохнет нанести крем с прополисом. <br />
                                В случае если на коже мокнущая сыпь (или рана), то крем с прополисом не используем. Его добавляем на этапе заживления (когда образуется корка)
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Лишний вес
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/big-weight.jpeg" />
                              </p>    
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик: 
                              </p>    
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>    
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>    
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>    
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>    
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>    
                              <p>
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>    
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>    
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>    
                              <p>
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день. 
                              </p>    
                              <p>
                                Употреблять сразу после еды, запивая небольшим количеством воды. 
                              </p>    
                              <p class="mt-10 p-title">
                                Коктейль Слим актив:
                              </p>    
                              <p>
                                На завтрак (едите белковую пищу) + 3 ст.л порошка коктейля(28г) на 300 мл нежирного кефира/йогурта/1,5 % молока                   
                              </p>    
                              <p>
                                Ужин: 3 ст. л порошка коктейля (28г)+500мл нежирного кефира/ряженки/натурального йогурта, но не молока!
                              </p>    
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>    
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном. 
                              </p>    
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>    
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после. 
                              </p>    
                              <p class="mt-10 p-title">
                                Селл Эссенс Регенерэйшн: 
                              </p>    
                              <p>
                                Принимать вечером. Растворить 1,5 мерные ложки (4,7 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте. 
                              </p>    
                              <p class="mt-10 p-title">
                                Селл Эссенс Энерджи: 
                              </p>    
                              <p>
                                Принимать днём. Растворить 1 мерную ложку порошка (3,4 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте. 
                              </p>    
                              <p class="mt-10 p-title">
                                Чай Фастинг:
                              </p>    
                              <p>
                                1-2 ч.л. с горкой залить горячей водой в большой чашке(200 мл), дать настояться 5-7 минут и затем процедить. <br />
                                Рекомендуется пить 3-4 чашки в день.
                              </p>               
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  ЛОР-заболевания
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/lor.jpeg" />
                              </p>                
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Иммун плюс: 
                              </p>                
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>                
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>                
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>                
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>                
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой. 
                              </p>                
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>                
                              <p>
                                1 капсула -2 раза в день 
                              </p>                
                              <p>
                                <strong>Дети с 3 лет: </strong>
                                профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>                
                              <p>
                                <strong>С лечебной целью по схеме: </strong>
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>                
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>                
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>                
                              <p class="mt-10 p-title">
                                Спрей Скорая помощь. 
                              </p>                
                              <p>
                                <strong>При насморке: </strong><br />
                                Брызгать в нос -3-4 раза в день 
                              </p>                
                              <p>
                                <strong>При боле в горле: </strong><br />
                                Орошать горло 3-4 раза в день.
                              </p>                
                              <p>
                                <strong>При болях в ушах: </strong><br />
                                Спрей Скорая помощь наносить на ватные диски, закладывать в уши на ночь. 
                              </p>                
                              <p>
                                Гель концентрат закладывать в клылья носа, и на проекцию пазух. Раз 5-6 в день.
                              </p>   
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Мастопатия
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/mastopatiya.jpeg" />
                              </p>             
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Сивера: 
                              </p>             
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>             
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>             
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>             
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>             
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>             
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>             
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>             
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>             
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>             
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>             
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>             
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>             
                              <p class="mt-10 p-title">
                                Бьюти элексир: 
                              </p>             
                              <p>
                                Принимать ежедневно по одному флакону. <br />
                                Идеально подходит для женщин в возрасте 25+ , которые хотят продлить или  вернуть молодость. <br />
                                Также подходит для мужчин. <br />
                                Перед использованием встряхнуть. <br />
                                Хранить в сухом месте, при комнатной температуре.
                              </p>             
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>             
                              <p>
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>             
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой.
                              </p>             
                              <p>
                                Гель концентрат наносить наружно (минимум) 2-3 раза в день.
                              </p>      
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Мочеполовая система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/mocha-sys.jpeg" />
                              </p>           
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Мёд:
                              </p>           
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы.  <br />
                                С 11 дня - полная норма. 
                              </p>           
                              <p>
                                <strong>Возрастная норма: </strong> <br />
                                До 1 года-3 раза в день по 1 мл   <br />
                                До 5 лет-3 раза в день по 5 мл   <br />
                                До 10 лет- 3 раза в день по 10 мл <br />
                                До 15 лет - 3 раза в день по 15 мл  <br />
                                До 20 лет - 3 раза в день по 20 мл  <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>           
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>           
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>           
                              <p>
                                Первые 5 дней 1/3 от нормы  <br />
                                Следующие 5 дней 2/3 от нормы  <br />
                                С 11 дня - полная норма 
                              </p>           
                              <p>
                                До 5 лет - 1 раз по 1 таблетке  <br />
                                До 10 лет - 3 раза по 1 таблетке  <br />
                                До 20 лет - 3 раза по 2 таблетки  <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>           
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>           
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>           
                              <p>
                                Первые 5 дней 1/2 от нормы.  <br />
                                С 6 дня - полная норма. 
                              </p>           
                              <p>
                                До 5 лет - 1 раз по 10 мл.  <br />
                                До 10 лет - 1 раз по 20 мл <br />
                                До 15 лет - 1 раз по 30 мл  <br />
                                До 20 лет - 1 раз по 50 мл  <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>           
                              <p>
                                Хранить в холодильнике.  <br />
                                Употреблять во время или после еды.  <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>           
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>           
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день  <br />
                                До 20 лет - 1 капсула 2 раза в день  <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>           
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>           
                              <p>
                                1 капсула -2 раза в день 
                              </p>           
                              <p>
                                Дети с 3 лет: профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>           
                              <p>
                                С лечебной целью по схеме: 
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка.  <br />
                                Принимать во время еды. 
                              </p>           
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>           
                              <p>
                                3 нажатия - 3 раза в день  <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день.  <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>           
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>           
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>           
                              <p>
                                Подходит для любой возрастной группы.  <br />
                                Хранить в сухом прохладном месте.
                              </p>           
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>           
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить.  <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>        
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Нервная система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/nerv-sys.jpeg" />
                              </p>                   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>                   
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>                   
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>                   
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>                   
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>                   
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>                   
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой.
                              </p>                   
                              <p class="mt-10 p-title">
                                Найт мастер: 
                              </p>                   
                              <p>
                                содержимое одного пакетика растворить в 100 мл воды и принять за 30 минут до сна.
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Новообразования
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/novoobrazovaniya.jpeg" />
                              </p>        
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик: 
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>        
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>        
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>        
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>        
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>        
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>        
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>        
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>        
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>        
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>        
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>        
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>        
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>        
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>        
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>        
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>        
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>           
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Нормализация давления
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/norm-davl.jpeg" />
                              </p>     
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Сивера: 
                              </p>     
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>     
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>     
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>     
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                                </p>     
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>     
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>     
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>     
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>     
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>     
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>     
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>     
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>     
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>     
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>     
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>     
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>     
                              <p class="mt-10 p-title">
                                Селл Эссенс Регенерэйшн: 
                              </p>     
                              <p>
                                Принимать вечером. Растворить 1,5 мерные ложки (4,7 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте.
                              </p>              
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Нормализация ЖКТ
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/norm-gkt.jpeg" />
                              </p>                   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик:
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>                   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
</p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>                   
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>                   
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>                   
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>                   
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном.
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Опорно-двитательная система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/oporno-sys.jpeg" />
                              </p>            
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Фридом:
                              </p>            
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>            
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>            
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>            
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>            
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>            
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>            
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>            
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>            
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>            
                              <p class="mt-10 p-title">
                                Капсулы Фридом: 
                              </p>            
                              <p>
                                С 3 лет принимать по 1 капсуле 2 раза в день. После приёма пищи, запивая стаканом воды.
                              </p>            
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>            
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>            
                              <p class="mt-10 p-title">
                                Бьюти элексир: 
                              </p>            
                              <p>
                                Принимать ежедневно по одному флакону. <br />
                                Идеально подходит для женщин в возрасте 25+ , которые хотят продлить или вернуть молодость. <br />
                                Также подходит для мужчин. <br />
                                Перед использованием встряхнуть. <br />
                                Хранить в сухом месте, при комнатной температуре.
                              </p>            
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>            
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>            
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>            
                              <p>
                                Мсм гель для тела, гель концентрат, термолосьон наружно на область поражённых суставов и позвоночника.
                              </p>       
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  ОРВИ
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/orvi.jpeg" />
                              </p>                   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Иммун плюс: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>                   
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>                   
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>                   
                              <p>
                                1 капсула -2 раза в день 
                              </p>                   
                              <p>
                                Дети с 3 лет: профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>                   
                              <p>
                                С лечебной целью по схеме: <br />
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>                   
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>                   
                              <p class="mt-10 p-title">
                                Спрей Скорая помощь. 
                              </p>                   
                              <p>
                                При насморке: 
                                Брызгать в нос -3-4 раза в день 
                              </p>                   
                              <p>
                                При боле в горле: 
                                Орошать горло 3-4 раза в день.
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Очищение кишечника
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/clear-gkt.jpeg" />
                              </p>        
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик:
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>        
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>        
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>        
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>        
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>        
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>        
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>        
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после. 
                              </p>        
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>        
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>        
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>        
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном.
                              </p>        
                              <p class="mt-10 p-title">
                                Чай Фастинг:
                              </p>        
                              <p>
                                1-2 ч.л. с горкой залить горячей водой в большой чашке(200 мл), дать настояться 5-7 минут и затем процедить. <br />
                                Рекомендуется пить 3-4 чашки в день.
                              </p>           
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Паппиломы
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/papiloma.jpeg" />
                              </p>   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик: 
                              </p>   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                                </p>   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой.
                              </p>   
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>   
                              <p>
                                1 капсула -2 раза в день 
                              </p>   
                              <p>
                                Дети с 3 лет: профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>   
                              <p>
                                С лечебной целью по схеме: 
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>   
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>   
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>   
                              <p>
                                Гель концентрат наружно, минимум 2-3 раза в день.
                              </p>                
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Псориаз
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/psoriaz.jpeg" />
                              </p>          
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик:
                              </p>          
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>          
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>          
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>          
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                                </p>          
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>          
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>          
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>          
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>          
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>          
                              <p class="mt-10 p-title">
                                Файбер буст: 
                              </p>          
                              <p>
                                1 порция -1 раз в день <br />
                                Дети с 3 лет: 1/2 порции 1 раз в день. <br />
                                Растворить 2 мерные ложки без горки в 200 мл воды (обезжиренного молока) и сразу же употребить. <br />
                                Лучше принимать перед сном.
                              </p>          
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>          
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>          
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>          
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>          
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>          
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>          
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>          
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>          
                              <p class="mt-10 p-title">
                                Аптечка первая помощь наружно: 
                              </p>          
                              <p>
                                на поражённые участки кожи наносим сначала спрей Скорая помощь, через минуту концентрат. После того как концентрат подсохнет нанести крем с прополисом. <br />
                                В случае если на коже мокнущая сыпь (или рана), то крем с прополисом не используем. Его добавляем на этапе заживления (когда образуется корка)
                              </p>         
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Реабилитация после Covid
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/after-covid.jpeg" />
                              </p>           
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик/Алоэ Вера гель Сивера: 
                              </p>           
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>           
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>           
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>           
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
</p>           
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>           
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>           
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>           
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>           
                              <p>
                                До 3 лет - 1 раз в день по 2 мл <br />
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>           
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой. 
                              </p>           
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>           
                              <p>
                                С 3 до 15 лет - 1 капсула 1 раз в день. <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день. 
                              </p>           
                              <p>
                                Употреблять сразу после еды, запивая небольшим количеством воды. 
                              </p>           
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>           
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после. 
                              </p>           
                              <p class="mt-10 p-title">
                                Селл Эссенс Регенерэйшн: 
                              </p>           
                              <p>
                                Принимать вечером. Растворить 1,5 мерные ложки (4,7 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте. 
                              </p>           
                              <p class="mt-10 p-title">
                                Селл Эссенс Энерджи: 
                              </p>           
                              <p>
                                Принимать днём. Растворить 1 мерную ложку порошка (3,4 г) в 100 мл воды и сразу выпить. <br />
                                Хранить в сухом прохладном месте.
                              </p>           
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>           
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день. 
                              </p>           
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>           
                              <p>
                                1 капсула -2 раза в день 
                              </p>           
                              <p>
                                Дети с 3 лет: профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>           
                              <p>
                                С лечебной целью по схеме: 
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>           
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>           
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>        
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Сердечно-сосудистая система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/heart-sys.jpeg" />
                              </p>         
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Сивера: 
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>         
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>         
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>         
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>         
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>         
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>         
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>         
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>         
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>         
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>         
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>         
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>         
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>         
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>         
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>         
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>         
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>         
                              <p>
                                Гель концентрат и мсм боди гель наносить по ходу вен (при варикозе)
                              </p>          
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Хондроз, артроз, коксартроз
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/koksoartroz.jpeg" />
                              </p>      
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Фридом:
                              </p>      
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>      
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>      
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>      
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                                </p>      
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>      
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>      
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>      
                              <p class="mt-10 p-title">
                                Селл Эссенс Фуд: 
                              </p>      
                              <p>
                                Принимать утро. Растворить 2 мерные ложки порошка(6 г) в 100 мл воды и сразу выпить. <br />
                                Не рекомендуется принимать с кашами, абсорбирует минералы). Можно за пол часа до каши или через час после.
                              </p>      
                              <p class="mt-10 p-title">
                                Капсулы Фридом: 
                              </p>      
                              <p>
                                С 3 лет принимать по 1 капсуле 2 раза в день. После приёма пищи, запивая стаканом воды.
                              </p>      
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>      
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>      
                              <p>
                                Мсм гель для тела и гель концентрат наружно. <br />
                                На область суставов 2-3 раза в день.
                              </p>             
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Цистит
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/cistit.jpeg" />
                              </p>         
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Мёд:
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>         
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>         
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
</p>         
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>         
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>         
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>         
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>         
                              <p class="mt-10 p-title">
                                Пробиотик про12: 
                              </p>         
                              <p>
                                от 1 капсулы х 1 раз в день до 1 капсулы x 3 раза в день.
                              </p>         
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>         
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>         
                              <p class="mt-10 p-title">
                                Цистус инканус капсулы:
                              </p>         
                              <p>
                                1 капсула -2 раза в день 
                              </p>         
                              <p>
                                Дети с 3 лет: профилактика - 1 капсула за 30 кг веса в сутки. 
                              </p>         
                              <p>
                                С лечебной целью по схеме: 
                                1 капсула на 10 кг веса -3 дня, далее на 20 кг веса - 2 дня, далее - профилактическая дозировка. <br />
                                Принимать во время еды. 
                              </p>         
                              <p class="mt-10 p-title">
                                Цистус инканус спрей:
                              </p>         
                              <p>
                                3 нажатия - 3 раза в день <br />
                                Дети с 3 лет - 1-2 нажатия - 1 раз в день. <br />
                                Перед употреблением взболтать. Хранить в холодильнике.
                              </p>          
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Эндокринная система
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/endo-sys.jpeg" />
                              </p>        
                              <p class="mt-10 p-title">
                                Алоэ Вера гель мёд: 
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>        
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>        
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>        
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>        
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>        
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>        
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>        
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>        
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>        
                              <p class="mt-10 p-title">
                                Гриб рейши: 
                              </p>        
                              <p>
                                По 1 капсуле в день, запивая стаканом воды. 
                              </p>        
                              <p>
                                Подходит для любой возрастной группы. <br />
                                Хранить в сухом прохладном месте.
                              </p>        
                              <p class="mt-10 p-title">
                                Бьюти элексир: 
                              </p>        
                              <p>
                                Принимать ежедневно по одному флакону. <br />
                                Идеально подходит для женщин в возрасте 25+ , которые хотят продлить или вернуть молодость. <br />
                                Также подходит для мужчин. <br />
                                Перед использованием встряхнуть. <br />
                                Хранить в сухом месте, при комнатной температуре.
                              </p>        
                              <p class="mt-10 p-title">
                                Вита Актив: 
                              </p>        
                              <p>
                                До 20 лет - 1 раз в день по 5 мл <br />
                                От 20 лет - 1 раз в день по 5-10 мл 
                              </p>        
                              <p>
                                Употреблять во время еды. (Желательно с белковой пищей - творог, омлет, йогурт и т.д.) <br />
                                Не смешивать и не запивать горячей водой.
                              </p>        
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>        
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>        
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>        
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>           
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="green">mdi-check</v-icon>
                                  Эпилепсия
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <v-img style="margin: 0 auto;" max-width="300px" src="/img/learn/req-health/epilepsiya.jpeg" />
                              </p>                   
                              <p class="mt-10 p-title">
                                Алоэ Вера гель Персик/Сивера:
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы. <br />
                                Следующие 5 дней 2/3 от нормы. <br />
                                С 11 дня - полная норма. 
                              </p>                   
                              <p>
                                <strong>Возрастная норма: </strong><br />
                                До 1 года-3 раза в день по 1 мл  <br />
                                До 5 лет-3 раза в день по 5 мл  <br />
                                До 10 лет- 3 раза в день по 10 мл<br />
                                До 15 лет - 3 раза в день по 15 мл <br />
                                До 20 лет - 3 раза в день по 20 мл <br />
                                От 20 лет - 3 раза в день по 30 мл 
                              </p>                   
                              <p>
                                Хранить в холодильнике. Употреблять до еды за 5-10 минут. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Минералы Пробаланс: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/3 от нормы <br />
                                Следующие 5 дней 2/3 от нормы <br />
                                С 11 дня - полная норма 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 1 таблетке <br />
                                До 10 лет - 3 раза по 1 таблетке <br />
                                До 20 лет - 3 раза по 2 таблетки <br />
                                От 20 лет - 3 раза по 3 таблетки 
                              </p>                   
                              <p>
                                Употреблять через 1 час после еды, обязательно запивая водой. 
                              </p>                   
                              <p class="mt-10 p-title">
                                Супер омега 3:
                              </p>                   
                              <p>
                                С 3 до 15 лет 1 капсула 1 раз в день <br />
                                До 20 лет - 1 капсула 2 раза в день <br />
                                От 20 лет - 1 капсула 3 раза в день.
                              </p>                   
                              <p class="mt-10 p-title">
                                Майнд мастер: 
                              </p>                   
                              <p>
                                Первые 5 дней 1/2 от нормы. <br />
                                С 6 дня - полная норма. 
                              </p>                   
                              <p>
                                До 5 лет - 1 раз по 10 мл. <br />
                                До 10 лет - 1 раз по 20 мл<br />
                                До 15 лет - 1 раз по 30 мл <br />
                                До 20 лет - 1 раз по 50 мл <br />
                                От 20 лет - 1 раз по 80 мл. 
                              </p>                   
                              <p>
                                Хранить в холодильнике. <br />
                                Употреблять во время или после еды. <br />
                                Если после приёма чувствуете расслабленность, то принимайте после ужина.
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ReqHealthLearn",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAll: 'user/getProfileAll'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.p-title
  text-transform: uppercase
  font-weight: bold

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
